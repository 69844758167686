/* global $  */
import 'bootstrap';

class App
{
    miniCart;
    currencySwitcher;
    bootstrap;
    menuMoving = false;
    ecomms;

    constructor()
    {
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms($('form'));
        this.initFontAwesome();
        this.initMatrixContent();
        this.initStickyMenu();
        this.initStoryNewsSlider();
        this.initCollapse();
        $('body').css('opacity', 1);
        $(window).scroll(() => {
            this.initStickyMenu();
        });
        $(window).resize(() => {
            this.initStickyMenu();
        });
        console.log('App initialized');
    }

    initMatrixContent()
    {
        if ($('.matrix-block').length) {
            import(/* webpackChunkName: "matrixContent" */ './components/matrixContent');
        }
    }

    initStoryNewsSlider() {
        if ($('.our-story-news').length) {
            import(/* webpackChunkName: "slick" */ './components/slick').then(() => {
                $('.story-news-slider').slick({
                    rows: 0,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            });
        }
    }

    getEcomms()
    {
        if (!this.ecomms) {
            return import(/* webpackChunkName: "ecomms" */ '@Ecomms/src/js/components/ecomms').then((chunk) => {
                this.ecomms = new chunk.Ecomms($('#ecomms-cart-qty'));
            });
        }
        return Promise.resolve();
    }

    getBootstrap()
    {
        if (typeof this.bootstrap == 'undefined') {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }
    
    initFontAwesome()
    {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initForms($forms)
    {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($forms, (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
    }

    initToasts(elems)
    {
        if (!elems.length) {
            return;
        }
        this.getBootstrap().then((bootstrap) => {
            $.each(elems, function (i, elem) {
                new bootstrap.Toast(elem);
            });
        });
    }

    initTooltips(elems)
    {
        if (!elems.length) {
            return;
        }
        this.getBootstrap().then((bootstrap) => {
            $.each(elems, function (i, elem) {
                new bootstrap.Tooltip(elem);
            });
        });
    }

    addAlert(message, $elem, type = "danger", dismissible = false)
    {
        let _class = "alert alert-" + type;
        if (dismissible) {
            message += '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
            _class = _class + ' alert-dismissible fade show';
        }
        $('<div class="' + _class + '">' + message + '</div>').appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false)
    {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }

    toggleSpinner(elem, show)
    {
        let spinner = elem.find("[class^='spinner-']");
        if (spinner.length) {
            if (show) {
                spinner.show();
            } else {
                spinner.hide();
            }
        }
    }

    appendHeadHtml(html) {
        if (!html) {
            return;
        }

        // Prune out any link tags that are already included
        var $existingCss = $('link[href]');

        if ($existingCss.length) {
            var existingCss = [];
            var href;
            for (var i = 0; i < $existingCss.length; i++) {
                href = $existingCss.eq(i).attr('href').replace(/&/g, '&amp;');
                existingCss.push(this.escapeRegex(href));
            }

            const regexp = new RegExp(
                '<link\\s[^>]*href="(?:' + existingCss.join('|') + ')".*?></link>',
                'g'
            );

            html = html.replace(regexp, '');
        }

        $('head').append(html);
    }

    appendBodyHtml(html)
    {
        if (!html) {
            return;
        }

        // Prune out any script tags that are already included
        var $existingJs = $('script[src]');

        if ($existingJs.length) {
            var existingJs = [];
            var src;
            for (var i = 0; i < $existingJs.length; i++) {
                src = $existingJs.eq(i).attr('src').replace(/&/g, '&amp;');
                existingJs.push(this.escapeRegex(src));
            }

            var regexp = new RegExp(
                '<script\\s[^>]*src="(?:' + existingJs.join('|') + ')".*?></script>',
                'g'
            );

            html = html.replace(regexp, '');
        }

        $('body').append(html);
    }

    escapeRegex(str)
    {
        // h/t https://stackoverflow.com/a/9310752
        return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    initStickyMenu() {
        let scrollTop = $(window).scrollTop();
        if (scrollTop > 200) {
            $('.header-fixed').addClass('scrolled');
        } else {
            $('.header-fixed').removeClass('scrolled');
        }
    }

    initCollapse() {
        $(".products-collapse").click(() => {
            if ($(".proteins-menu").hasClass("show")) {
                $(".proteins-menu").removeClass("show");
            }
            if ($(".featured-menu").hasClass("show")) {
                $(".featured-menu").removeClass("show");
            }
        });
        $(".proteins-collapse").click(() => {
            if ($(".collections-menu").hasClass("show")) {
                $(".collections-menu").removeClass("show");
            }
            if ($(".featured-menu").hasClass("show")) {
                $(".featured-menu").removeClass("show");
            }
        });
        $(".features-collapse").click(() => {
            if ($(".collections-menu").hasClass("show")) {
                $(".collections-menu").removeClass("show");
            }
            if ($(".proteins-menu").hasClass("show")) {
                $(".proteins-menu").removeClass("show");
            }
        });
    }
}

export { App };